.profilePage-membersListInfo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }
}

.profilePage-membersListName {
  margin-left: 10px;
  p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
  }

  span {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    color: #8b8585;
  }
}
