@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Raleway:wght@600;700&family=Roboto&family=Roboto+Mono:wght@500;600;700&display=swap");

.modalNotCompanies-container {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalNotCompanies-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
}

.modalNotCompanies-containerBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalNotCompanies-ContainerBodyDescription {
  font-size: 1.2rem;
  margin: 0.6rem;
  text-align: center;
}

.modalNotCompanies-containerTitle {
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: "Raleway", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0px;
    align-self: center;
  }

  img {
    margin-left: 62px;
    cursor: pointer;
  }
}

.modalNotCompanies-containerImg {
  display: flex;
  margin-top: 83px;
  p {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #111111;
    padding: 20px;
  }
  span {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    color: #111111;
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.modalNotCompanies-containerBtn {
  display: flex;
  justify-content: center;

  button {
    background-color: #2c5999;
    border: none;
    border-radius: 10px;
    height: 46px;
    width: 420px;
    margin-top: 1.4rem;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    color: white;
  }
}
