@media only screen {
  .adminpanel-field {
    input {
      width: 16rem;
    }
  }

  .redirect-box-container {
    width: 33rem;
  }
}
@media only screen and (min-width: 64.063em) {
  .adminpanel-field {
    input {
      width: 25rem;
    }
  }

  .redirect-box-container {
    width: 23rem;
  }
}

.adminpanel-field {
  display: flex;
  flex-direction: column;
  label {
    font-size: 1.3rem;
    font-weight: 500;
  }
  input {
    border-color: #2c5999;
    box-shadow: 0 0 0 0.2rem rgba(#2c5999, 0.25);
    border-radius: 10px;
    border: 1px solid #d2d7de;
    padding: 0.6rem;
  }
  input:focus {
    border-color: #2c5999;
    box-shadow: 0 0 0 0.2rem rgba(#2c5999, 0.25);
    outline: solid 1px #2c5999;
  }
}

.inputTypeSelect-Container {
  display: flex;
  flex-direction: column;

  .inputSelect-Field {
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid #d2d7de;
    padding: 0.6rem;
    color: #8a8995;
    padding-right: 0.9em;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../../../../assets//images/icons/arrow_down.svg");
    background-repeat: no-repeat;
    background-position-x: 92%;
    background-position-y: 50%;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    margin-right: 1.1rem;
    padding: 1rem;
    padding-right: 2rem;
    width: 16rem;
  }

  .inputSelect-Label {
    font-size: 1.3rem;
    font-weight: 500;
  }
}

.inputSelectNumber-Container {
  display: flex;
  flex-direction: column;
  label {
    font-size: 1.3rem;
    font-weight: 500;
  }

  .inputSelector {
    border: 1px solid #d2d7de;
    border-radius: 10px;
    width: 5rem;
    padding: 0.8rem;
  }
}

.redirect-box-container {
  height: 15rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 1rem;
  margin: 1.5rem;
  font-family: "raleway";
  filter: drop-shadow(0px 0px 16px rgba(17, 17, 17, 0.1));
  background-color: #ffff;

  .redirect-box-image {
    display: flex;
    justify-content: center;
  }

  .redirect-box-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 80%;
    .redirect-box-textTitle {
      font-size: 1.5rem;
      font-weight: 700;
    }
    .redirect-box-textDescription {
      font-size: 1.2rem;
      font-weight: 400;
    }
  }
}

.panelboxes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.adminpanel-title {
  font-family: "raleway";
  margin-top: 1rem;
}
