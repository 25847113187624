.referal-container {
  // background-color: #F8F8F8;
  height: 100vh;
  margin-left: 40px;
  padding: 30px;

  h1 {
    font-family: "Raleway", sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: -0.02em;
  }
}

.referal-section {
  max-width: 1200px;
  background-color: white;
  margin-top: 33px;
  border-radius: 6px;
  padding: 30px;
  border: 1px solid #c5cfd6;

  h3 {
    font-family: "Raleway", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  p {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    margin-top: 20px;
  }

  svg {
    margin-top: 5px;
    margin-right: 10px;
  }
}

.landingLink {
  background: #f0f0f0;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-top: 28px;

  img {
    cursor: pointer;
  }
}

.referal-socialMedia {
  display: flex;
  width: 20%;
  align-items: center;
  margin-top: 21px;

  img {
    margin-right: 12px;
    cursor: pointer;
  }
}

.referal-resourcesAndImg {
  display: flex;
  align-items: center;
  // width: 80%;
  // justify-content: space-between;
}

.referal-allResources {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.referal-resource {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
  img {
    width: 28px;
    height: 28px;
  }

  &.video {
    img {
      width: 28px;
      height: 20px;
    }
    border-bottom: none;
  }
}

.referal-resourceInfo {
  margin-left: 20px;

  p {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0px;
  }

  span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    color: #999999;
  }
}
