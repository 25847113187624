body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg {
  vertical-align: text-bottom !important;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 260px !important;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f {
  color: #000 !important;
  font-weight: 500;
  font-size: 16px;
}
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navicon---3gCRo
  > *,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-
  > .sidenav---navitem---9uL5T
  .sidenav---navtext---1AE_f
  > * {
  color: #222 !important;
  text-decoration: none;
}
