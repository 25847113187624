.main-container {
  padding-left: 2.5rem;
  padding-top: 4rem;
}

.form-input {
  border-radius: 5px;
  border: 1px solid #d2d7de;
  height: 46px;
  padding: 10px;
  width: 100%;

  &:focus {
    outline: 2px solid #2c5999;
  }
}

.error-msg {
  margin-top: 10px;
  font-size: 13px;
  color: #CC3333;
}

.danger-button{
  background-color: rgb(240, 12, 12);
  border: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0px;
  color: white;
  border-radius: 5px;
  height: 46px;
  width: 100%;
}

.form-max-width {
  max-width: 1000px;
}