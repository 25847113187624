body {
  font-size: "Inter", sans-serif;
  background-color: "#fdfdfd";
}

p {
  line-height: 1.3;
}

/*
  New Styles
  */

.rowPage {
  margin: 0;
  width: 100%;
}

input[type="file"] {
  display: none;
}

.companyColors {
  margin-top: 10px;
  display: flex;
  li {
    display: flex;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  li:hover {
    border: solid thin #000;
    cursor: pointer;
  }
}

.startCover {
  background-color: #7a4199;
  /*background: url("../images/startCover.png");*/
  padding: 0px 68px;
  /*background-size: cover;*/
  /*background-position: center;*/
  min-height: 100vh;
  width: 50%;
  position: absolute;
  right: 0;
  /*text-align: center;*/
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  img {
    width: 100%;
  }
  @media only screen and (max-width: 1445px) {
    width: 50%;
    padding: 10px;
  }

  @media only screen and (max-width: 1215px) {
    width: 50%;
    padding: 0px;
    .boxSliderImage {
      display: flex;
      justify-content: center;
    }
    .boxSliderParagraph {
      padding: 60px 60px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    img {
      width: 85%;
    }
  }

  @media only screen and (max-width: 1170px) {
    width: 50%;
    padding: 0px;
    .boxSliderImage {
      width: 420px;
      height: 370px;
      display: flex;
      padding: 0;
      justify-content: center;
      flex-direction: column;
    }
    .boxSliderParagraph {
      padding: 60px 170px 0px 97px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    img {
      width: 85%;
    }
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.columnIntro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  form {
    width: 85%;
  }
  @media only screen and (max-width: 768px) {
    margin: 120px 0;
    form {
      width: 100%;
    }
    .form-group {
      img {
        left: 10px;
      }
    }
  }
}

/*
  End New Styles
  */
.rowFilter {
  background: #eff0f6;
  width: 100% !important;
  max-width: none;
  padding: 0 5%;
}
.rowLimit {
  width: 90%;
  margin: auto;
}

.rowWidth {
  padding-top: 100px;
  max-width: 1280px;
}

.rowWidth-finishedAssessment {
  padding-top: 10px;
  max-width: 1280px;
}

.rowBreak {
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    text-align: center;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundStripe {
  /*background: url("../images/backgroundStripe.svg");*/
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.columnCover {
  display: flex;
  height: 100%;
}

.columnCover-img {
  width: 100%;
}

.component-wrapper {
  padding: 30px;
}

.component-min-wrapper {
  padding: 10px;
}

.component-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: auto;
  max-width: 1280px;
  width: 95%;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.item-card {
  border-radius: 20px;
  margin: 0 10px;
  padding: 30px;
  box-shadow: 0px 0px 16px rgb(17 17 17 / 10%);
  text-align: center;
  img {
    margin-bottom: 30px;
  }
}

.card-title {
  margin-bottom: 30px;
}

.btn-primary {
  border-color: #fff !important;
}

.btn-text {
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.btn-text:hover {
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.btn-styleAlt {
  background-color: #eaeaea !important;
  color: #000;
  margin-right: 20px;
  &:hover {
    background-color: #000 !important;
  }
}

.custom-h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  margin-bottom: 0;
  padding-bottom: 0;
  color: #14142b;
  span {
    color: #33b3d1;
  }
  &__alt {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 120%;
    color: #fff;
    text-align: left;
    margin: 50px auto;
    width: 60%;
  }
}

.custom-h2 {
  font-family: "Cy", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
}

.custom-h3 {
  font-family: "Cy", sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
}

.custom-h4 {
  color: #999999;
  font-family: "Cy", sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
}

.body-intro {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
}

.custom-mini-btn {
  align-items: center;
  background: #2c5999;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  height: 28px;
  border-color: #2c5999;
  font-weight: bold;
  font-size: 0.75rem;
}

.custom-mini-btn:hover {
  color: #2c5999;
  background-color: #fff;
  border-color: #2c5999;
  font-weight: bold;
  font-size: 0.75rem;
}

.custom-focus-card {
  border-radius: 20px;
  border: none;
  height: 7.5rem;
}

.card-background-color {
  background-color: whitesmoke;
}

.question-text,
.form-check-label {
  color: #6e7191 !important;
  font-family: "Cy", sans-serif;
  font-weight: 500 !important;
  font-size: 1rem;
  letter-spacing: 0.75px;
  line-height: 160%;
  text-decoration: none;
}

.cardToggle {
  box-shadow: 0px 0px 16px rgb(17 17 17 / 10%);
}

.cardToggle-icon {
  left: 25px;
  position: absolute;
  top: 25px;
}

.form-control {
  color: #000;
  height: 45px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #d2d7de;
  font-family: "Inter", sans-serif;
  transition: all 0.25s ease;
}

.form-control:focus {
  border-color: #2c5999;
  box-shadow: 0 0 0 0.2rem rgba(#2c5999, 0.25);
}

.inputName {
  padding-left: 60px;
}

.labelInput {
  font-size: 16px;
}

.inputNewBrand {
  padding: 0px 15px;
}

.selectDiagnostic {
  padding: 0px 25px;
  width: 160px;
  height: 35px;
  font-size: 14px;
  color: rgba(20, 20, 43, 0.5);
}

.calendarIcon {
  position: absolute;
  margin-top: 10px;
  padding: 0 !important;
  margin-right: 0 !important;
  margin-left: 10px;
  background: none !important;
}

.input-icon {
  position: absolute;
  padding: 10px 20px;
}

.btn-min-top {
  max-width: 240px !important;
  margin-top: 10px;
  margin-left: auto;
}

.btn-min-tag {
  background: #2c5999;
  color: #fff;
  height: 22px;
  font-size: 12px;
  font-weight: normal;
  padding: 0 25px;
  margin-top: 0;
  max-width: 240px !important;
  margin-left: auto;
  &:hover {
    font-weight: normal;

    height: 22px;
  }
}

.float-right {
  float: right;
}

.progress {
  border-radius: 20px;
  height: 22px;
  width: 100%;
}
.progress-bar {
  background-color: #2c5999;
  border-radius: 20px;
}

.nav-link.active {
  color: #51af4a !important;
  font-weight: bold;
}

.Main__GoalsCard-iexAQH {
  .card-title {
    margin-bottom: 0;
  }
}

.TacticGoalProgress {
  background: #dde3e9;
  border-radius: 12px;
  padding: 15px;
  .progress {
    margin-bottom: 15px;
  }
  p {
    color: #2c5999;
    font-weight: bold;
  }
}

.inlineText {
  display: inline-block;
}

.show {
  display: block;
}

.noShow {
  display: none;
}

.returnArrow {
  max-width: 420px !important;
  text-align: center;
  a {
    color: #2c5999;
  }
}

.btn-style {
  background-color: #2c5999;
  border-radius: 5px;
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 100%;
  transition: all 0.25s ease;
}

.btn-style:hover {
  background-color: #33b3d1;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
}

.btn-card-style {
  margin-top: 10px;
  max-width: 240px;
  background-color: #2c5999;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 100%;
  transition: all 0.25s ease;
}

.btn-card-style:hover {
  background-color: #33b3d1;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
}

.btn-plain {
  background-color: #fff;
  border: solid 1px #2c5999;
  border-radius: 40px;
  align-items: center;
  color: #2c5999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 33px;
  width: 120px;
  transition: all 0.25s ease;
}

.btn-plain:hover {
  background-color: #33b3d1;
  color: #fff;
  border-radius: 40px;
  text-decoration: none;
}

.rowDashboard {
  padding-top: 0;
}

.rowMenuMobile {
  display: none;
}

@media screen and (max-width: 1500px) and (min-width: 901px) {
  .rowDashboard {
    padding-left: 40px;
  }
}

@media screen and (max-width: 900px) {
  .side-bar {
    display: none;
  }
  .rowLimit {
    width: 100%;
  }
  .rowMenuMobile {
    display: block;
  }
  .JAbIa {
    margin-top: 15px;
  }
}

.editInfo-cont {
  border-left: 1px solid #afb9c5;
  padding-left: 30px;
}

.editInfo-form {
  width: 90%;
  max-width: 600px;
}

.activeCheck {
  position: absolute;
  top: 22%;
  left: 5px;
  width: 35px;
  height: 35px;
}

.dashboardCardRow {
  margin: 30px 0 50px;
}

@media only screen and (max-width: 900px) {
  .backgroundStripe,
  .backgroundStart {
    background: none;
  }
  .rowBreak {
    flex-direction: column-reverse;
  }
  .editInfo-links {
    padding-left: 30px;
    margin-bottom: 30px;
  }
}

.loginButton {
  margin-left: 0;
}

.mainButton {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #2c5999;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 45px;
  max-width: 420px;
  width: 100%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.frmInput {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.frmInput:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

.frmError {
  border-color: red;
  color: red;
  font-size: 14px;
  margin-top: -10px;
  padding: 5px;
  margin-bottom: 10px;
}

.frmLabel {
  font-weight: bold;
  display: block;
  margin-top: 10px;
  margin-bottom: 0.5rem;
}

.frmButton {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #2c5999;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
  height: 45px;
  /*max-width: 420px;*/
  width: 100%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;


}

.frmButton:hover {
  align-items: center;
  background: #33b3d1;
  border: none;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 100%;
}

.frmButtonFlat {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border: solid thin #d2d7de;
  border-radius: 10px;
  color: #2c5999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
  height: 45px;
  /*max-width: 420px;*/
  width: 100%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.frmButtonFlat:hover {
  align-items: center;
  background: #f1f1f1;
  border: none;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 100%;
}

.frmButtonDisabled {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: none;
  border-radius: 5px;
  color: #999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
  height: 45px;
  /*max-width: 420px;*/
  width: 100%;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
}

.assessmentChartResult {
  height: 350px;
  margin-bottom: 30px;
  width: 85%;
}

.resultCard {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  padding: 30px;
  width: 90%;
}

.formCard {
  background: #fff;
  border-radius: 30px;
  margin: 30px 0;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
}

.btn-backsite-style {
  width: auto;
  position: absolute;
  padding: 15px;
  border-radius: 6px;
  background: #7a4199;
  margin: 0;
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.btn-backsite-style:hover {
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.home-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 25px;
  }
}
.ReactModal__Content {
  border: none !important;
  border-radius: 6px;
  box-shadow: 0px 0px 15px rgba(17, 17, 17, 0.1);
  max-width: 800px;
  width: 90%;
}

@media only screen and (max-width: 800px) {
  .assessmentChartResult {
    width: 100%;
  }
  .resultCard {
    width: 100%;
  }
}
