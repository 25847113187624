/* page about members */
.invitationForm-Container {
  width: 100%;
  height: 100vh;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  .profilePage-membersHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .profilePage-memberBackbtn-container {
      display: flex;
      align-items: center;
      margin-top: 2.5rem;
      .profilePage-memberBackbtnTxt {
        display: flex;
        align-items: center;
        span {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.profilePage-noMembersMsg {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  width: 70%;
  align-self: center;
  color: #999999;
  margin-top: 82px;
}

.profilePage-formContainer {
  margin-top: 35px;
}

.profilePage-inputContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  input {
    border-radius: 5px;
    width: 80%;
    border: 1px solid #d2d7de;
    height: 46px;
    padding: 10px;
    &:focus {
      outline: 2px solid #2c5999;
    }
  }

  button {
    width: 15%;
    background-color: #7a4199;
    border: none;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: 0px;
    color: white;
    border-radius: 5px;
    height: 46px;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.profilePage-formError {
  color: red;
  margin-top: 10px;
  font-size: 13px;
}

.profilePage-membersContainer {
  width: 100%;
  padding: 0.3rem;
  height: 20rem;
}

.configuration-titleContainer {
  margin-top: 60px;
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
}

.configuration-subtitleContainer {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  color: #999999;
  margin-bottom: 45px;
}
