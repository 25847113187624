@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Raleway:wght@600;700&family=Roboto&family=Roboto+Mono:wght@500;600;700&display=swap");

@media only screen {
  .configurationHeader-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1.8rem;
  }
}
@media only screen and (min-width: 64.063em) {
  .configurationHeader-container {
    margin: 1rem;
  }
}

.configuration-container {
  height: 110%;
  width: 100%;
  display: flex;
  margin-top: -3.5rem;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.configurationHeader-container {
  display: flex;
  align-items: center;
  font-family: "raleway";

  .configurationHeader-title {
    color: #14142b;
    font-weight: 700;
    font-size: 2.4rem;
  }
  .configurationHeader-subtitle {
    color: #979797;
    font-family: "inter";
    font-weight: 400;
    font-size: 1rem;
    margin-left: 1rem;
  }
}

.configuration-frm {
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  width: 100%;

  @media only screen and (min-width: 1000px) {
    width: 70%;
    overflow-y: auto;
    // overflow-y: hidden;
  }

  .container-loading {
    width: 110px;
    height: 110px;
    align-self: center;
  }
}

.configuration-titleContainer {
  margin-top: 60px;
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
}

.configuration-section {
  width: 30%;
  background-color: #eff0f6;
  padding: 0px 25px;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }

  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 110px;
    @media only screen and (max-width: 1000px) {
      margin-top: 20px;
    }
  }

  p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    margin-top: 20px;
  }

  ul {
    margin-top: 25px;
    margin-bottom: 30px;
    li {
      width: 288px;
      height: 50px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 10px;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      cursor: pointer;
      margin-top: 5px;

      &.active {
        background-color: #d9dcee;
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        color: #000000;

        @media only screen and (max-width: 1150px) {
          width: 100%;
        }
      }

      &:hover {
        background-color: #d9dcee;
        @media only screen and (max-width: 1150px) {
          width: 100%;
        }
      }
    }
  }
}

.form-control {
  padding: 0px 15px;
}

.configuration-containerIMG {
  @media only screen and (max-width: 1150px) {
    width: 75px !important;
    height: 75px !important;
  }
}

.configuration-containerIMG-logotype {
  @media only screen and (max-width: 1150px) {
    width: 75px !important;
  }
}

.container-logos {
  @media only screen and (max-width: 1082px) {
    flex-wrap: nowrap;
    flex-direction: column;
    height: auto;
  }
}
.container-fluid {
  padding-right: 0;
}
