@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Raleway:wght@600;700&family=Roboto&family=Roboto+Mono:wght@500;600;700&display=swap');

.modalLogos-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.modalLogos-close {
  align-self: flex-end;
  cursor: pointer;
}

.modalLogos-contentContainer {
  padding: 0px 30px;

  h1 {
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    font-weight: 700;
  }

  p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    margin-top: 22px;

    span {
      color: #2C5999;
      font-weight: bold;
    }
  }
}

.modalLogos-exampleContainer {
  display: flex;
  align-items: center;
  margin-top: 33px;
  margin-bottom: 20px;
  p {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 400;
    padding: 0px 50px;
    line-height: 24px;
    span {
      font-family: 'Roboto Mono', monospace;
      font-size: 22px;
      font-weight: 500;
      color: #2C5999;
    }
  }

  img {
    filter: drop-shadow(0px 0px 16px rgba(17, 17, 17, 0.1));
    border-radius: 10px;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    p {
      font-size: 18px;
    }
  }
}