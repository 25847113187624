.errorBoundary-container {
  margin: 1.8rem;
}

.errorBoundary-title {
  margin-top: 1.9rem;
  font-family: "raleway";
  font-size: 2rem;
}

.getBackbtn {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1.8rem;
}
