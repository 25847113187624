.companies-containerGraphicCircular {
  background-color: white;
  margin-top: 27px;
  border-radius: 6px;
  width: 45%;
  // height: 156px;
  border: 1px solid #C5CFD6;
  padding: 35px;

  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1130px) {
    width: 100%;
  }

  @media only screen and (max-width: 530px) {
    flex-direction: column;
  }
}

.companies-containerPymesNum {
  display: flex;
  flex-direction: column;

  h5 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0px;
    color: #979797;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0px;

  }

  margin-right: 30px;

}

.companies-containerCircular {
  height: 156px;
}

/* sectors */
.companies-containerSectors {
  background-color: white;
  margin-top: 27px;
  border-radius: 6px;
  width: 45%;
  // min-height: 156px;
  border: 1px solid #C5CFD6;
  padding: 20px;
  margin-left: 3%;

  h5 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0px;
    color: #979797;
  }

  @media only screen and (max-width: 1130px) {
    width: 100%;
  }
}


.companies-containerSectorData {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  p {
    margin-left: 5px;
    font-size: 14px;
  }

  @media only screen and (max-width: 500px) {
    display: block;
  }
}

.companies-eachSectorData1 {
  display: flex;
  align-items: center;
  grid-column: 1 / 2;
}

.companies-eachSectorData2 {
  display: flex;
  align-items: center;
  grid-column: 2 / 3;
}

.companies-eachSectorData3 {
  display: flex;
  align-items: center;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.companies-eachSectorData4 {
  display: flex;
  align-items: center;
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.companies-eachPointSector {

  width: 15px;
  height: 15px;
  border-radius: 3px;

  &.sector1 {
    background-color: #3C62EB;
  }

  &.sector2 {
    background-color: #F1C33D;
  }

  &.sector3 {
    background-color: #B8D8E9;
  }

  &.sector4 {
    background-color: #EFF0F6;
  }

}

/* graphic horizontal */
.companies-graphicHorizontal {

  display: flex;
  height: 45px;
  margin-top: 15px;
  border-radius: 5px;
}

.companies-graphicItem1 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #3C62EB;

  &.finish {
    border-radius: 5px;
  }
}

.companies-graphicItem2 {
  background-color: #F1C33D;

  &.finish {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.companies-graphicItem3 {
  background-color: #B8D8E9;

  &.finish {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.companies-graphicItem4 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #EFF0F6;
}

/* list */
.companies-listTitle {
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0px;
  margin-top: 30px;
  margin-bottom: 18px;
}

.companies-link {
  color: #2C5999;
  text-align: center;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  align-self: center;
}

.companies-containerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
}

/* responsive */
.graphicsRow {
  @media only screen and (max-width: 1130px) {
    flex-direction: column;
    align-items: center;
  }
}

.companies-containerTable {
  // overflow-x: scroll;
  overflow-x: auto;
}

.companies-notSearch {
  text-align: center;
  color: #2C5999;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.companies-resetSearch {
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    margin-left: 5px;
    color: #2C5999;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
  }
  margin-bottom: 10px;
}