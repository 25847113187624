.container-checks {
  // background-color: red;
  display: flex;
}
.container-checkSecond {
  margin-left: 50px;
}
.onboarding-checkbox {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  cursor: pointer;
  p {
    margin-left: 10px;
  }
}

.onboarding-containerCheckbox {
  height: 32px;
  width: 32px;
  background-color: #2c5999;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onboarding-containerCheckboxInactive {
  height: 32px;
  width: 32px;
  background-color: #d6d8e7;
  border-radius: 100%;
}
.onboarding-checkTxt {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: black;
}

.onboardingStepForm {
  margin-left: 0px;
  padding: 20px;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 800px;
  label {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    color: #14142b;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      font-family: "Inter", sans-serif;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      color: rgba(20, 20, 43, 0.5);
    }
  }
}

.onboardingStepRow {
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  margin-bottom: 1.9rem;
  overflow: auto;
  display: flex;
}

.stepFormSelectionBtns {
  margin: 0.4rem;
  padding: 0.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.stepFormBtn {
  background-color: #2c5999;
  border-radius: 5px;
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 290px;
  margin: 1rem;
  padding: 1.6rem;
  transition: all 0.25s ease;
}

.stepBoxSelectOption {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConnectAccount-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AdminloginContainer {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.2rem;
  align-items: center;
}
