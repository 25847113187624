@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Raleway:wght@600;700&family=Roboto+Mono:wght@500;600;700&display=swap");
.invitationMember-container {
  display: flex;
  height: 100vh;
}

.landing-invitation-content {
  width: 100%;
  height: 100%;
}
.invitationMember-containerForm {
  // width: 50%;
  // padding: 60px;
  width: 100%;
  // padding: 60%;
  // padding: 10px;

  h1 {
    font-family: Raleway;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0px;
    margin-top: 30px;
  }

  // @media only screen and (max-width: 990px) {
  //   width: 100%
  // }
}

.invitationMember-formMsg {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #999999;
  margin-top: 30px;
  margin-bottom: 30px;
}
.invitationMember-form {
  width: 200%;
  input {
    border-radius: 10px;
    outline: 1px solid #d2d7de;
    height: 46px;
    margin-bottom: 10px;
    width: 80%;
    border: none;
    padding: 15px;
  }

  button {
    width: 80%;
    height: 46px;
    border-radius: 10px;
    background-color: #2c5999;
    color: white;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    margin-top: 25px;
    border: none;
  }
}

.invitationMember-label {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0px;
  color: #14142b;
}

.invitationMember-login {
  margin-top: 30px;
}

.invitationMember-formName {
  display: flex;
  width: 100%;

  div {
    width: 45%;
  }
}

.invitationMember-error {
  color: red;
  font-size: 14px;
}

.test {
  color: red;
  font-size: 30px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0px;
}

.invitationPage-btnHaveLogin {
  background-color: transparent;
  color: #2c5999;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  border: none;
  text-decoration: underline;
  text-align: left;
  margin-top: 5px;
  padding: 0;
  width: fit-content;
}

.column-intro {
}

.invitationLayout-container {
  width: 100%;
  height: 100%;
}

.invitationFormLayout {
  width: 100%;
}
