@media only screen {
  .community-profile-container {
    width: 20rem;
  }
}
@media only screen and (min-width: 64.063em) {
  .community-profile-container {
    width: 425px;
  }
}

.community-profile-container {
  background: #ffffff;
  height: 325px;
  border-radius: 12px;
  padding: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  filter: drop-shadow(0px 0px 16px rgba(17, 17, 17, 0.1));
  font-family: "raleway";
  .community-profile-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    img {
      width: 6.5rem;
      height: 6.5rem;
      border: 1px solid #dde3e9;
      border-radius: 10px;
    }
    .community-profile-header-title {
      margin-left: 1rem;
      margin-top: 1rem;
      height: 100%;
      .community-profile-header-titleMain {
        color: #979797;
      }
      .community-profile-header-titleName {
        font-weight: 500;
        font-size: 1.8rem;
      }
      .community-profile-header-titleCategory {
        font-weight: 400;
      }
    }
  }
  .community-profile-body {
    margin-bottom: 1rem;
    .community-profile-body-title {
      font-style: italic;
    }
  }
  .community-profile-footer {
    .community-profile-footer-row {
      display: flex;
      justify-content: space-between;
      .community-profile-footer-text {
        .community-profile-footer-textTitle {
          color: #979797;
        }
        .community-profile-footer-textLocation {
          font-weight: 600;
        }
      }

      .community-profile-footer-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #2c5999;
        color: #2c5999;
        background-color: #ffff;
        border-radius: 40px;
        width: 119px;
        .community-profile-footer-buttonText {
        }
      }
    }
  }
}
