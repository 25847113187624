.expanded-logo {
  width: 35px;
  height: auto;
  margin: 2px auto;
  transition: all 0.3s ease-in-out;
}

.normal-logo {
  width: 30px;
  height: auto;
  margin: 2px auto;
  transition: all 0.3s ease-in-out;
}

.menu-item-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.sidebar-logo-border {
  border-radius: 50%;
  border: 2px solid #161515;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
