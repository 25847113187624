@import url("https://use.typekit.net/wvx5jby.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Raleway:wght@600;700&family=Roboto+Mono:wght@500;600;700&display=swap");
.landing-content {
  max-width: 1440px;
  background-color: #fdfdfd;
  display: flex;
  height: 100vh;
}

.rowPage {
  margin: 0;
  width: 100%;
}

.main-content {
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.columnIntro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  form {
    width: 85%;
  }
  @media only screen and (max-width: 768px) {
    margin: 120px 0;
    form {
      width: 100%;
    }
    .form-group {
      img {
        left: 10px;
      }
    }
  }
}

/*
  Login 
*/
.loginContainer {
  margin: 1rem;
}

.logra-logo {
  margin: 1rem;
}

.logoPage {
  height: 50px;
  width: 110px;
  margin-bottom: 110px;
  margin-left: 10px;
}

.component-min-wrapper {
  padding: 10px;
}
.custom-h1 {
  margin-bottom: 0;
  padding-bottom: 0;
  color: #14142b;
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;

  span {
    color: #33b3d1;
  }
  &__alt {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 120%;
    color: #fff;
    text-align: left;
    margin: 50px auto;
    width: 60%;
  }
}
.custom-h4 {
  color: #999999;

  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.02em;
  margin-top: 22px;
}

.product-description {
  color: #999;

  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
}

.labelInput {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  // line-height: 46px;
  letter-spacing: 0px;
  color: #14142b;
  margin-top: 33px;
}

.inputNewBrand {
  padding: 0px 15px;
}

.main-btn-align {
  margin-top: 10px;
  width: 100%;
}

.btn-file {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  color: red;
}

.btn-primary {
  border-color: #fff !important;
}

.btn-style {
  background-color: #2c5999;
  border-radius: 5px;
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 100%;
  transition: all 0.25s ease;
}

.btn-style:hover {
  background-color: #33b3d1;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
}
.common-link-1 {
  font-family: "Cy", sans-serif;
  color: #2c5999;
  font-weight: bold;
  text-decoration-line: none;
  line-height: 28px;
  border-bottom: 2px solid #2c5999;
}
.common-link-1:hover {
  text-decoration-line: none;
}
.common-text-2 {
  color: #14142b;
  // line-height: 28px;
  // font-weight: 400;

  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
}

.stepPage-container {
  max-width: 1440px;
  display: flex;
  justify-content: left;
  height: 100vh;
}

.stepPage-content {
  margin-top: 5%;
  margin-left: 0px;
  padding: 0;

  overflow-y: scroll;
}

.colStepsDescription {
  background-color: #eff0f6;
  padding: 0 3%;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 50px;
  h3 {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
  }
  p {
    width: 295px;
    padding: 25px 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
  }
  ul {
    width: 290px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    li {
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin: 4px 0;
      padding: 16px 15px;
    }
    li.active {
      background: #d9dcee;
      border-radius: 6px;
      font-style: normal;
      font-weight: bold;
      color: #000000;
    }
  }
}

.stepBoxForm {
  margin-left: 0px;
  padding: 20px;
  width: 100%;
  // max-width: 620px;
  max-width: 800px;
  label {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    color: #14142b;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      font-family: "Inter", sans-serif;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      color: rgba(20, 20, 43, 0.5);
    }
  }
  .termAndCondition {
    display: flex;
    flex-direction: row;
    align-items: center;
    // position: absolute;
    // left: 30px;

    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 46px;
    letter-spacing: 0px;
    color: #14142b;
    a {
      color: #8945ad;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: 0px;
    }
  }
}

.onTitle {
  color: #14142b;
  padding: 0px 0px 14px;
  margin-left: 25px;

  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0px;
}

.onSubTitle {
  color: #999999;
  padding: 14px 0px 9px 0px;
  margin-left: 25px;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

.startCover {
  background-color: #7a4199;
  padding: 0px 68px;
  min-height: 100vh;
  width: 50%;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  img {
    width: 100%;
  }
  @media only screen and (max-width: 1445px) {
    width: 50%;
    padding: 10px;
  }

  @media only screen and (max-width: 1215px) {
    width: 50%;
    padding: 0px;
    .boxSliderImage {
      display: flex;
      justify-content: center;
    }
    .boxSliderParagraph {
      padding: 60px 60px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    img {
      width: 85%;
    }
  }

  @media only screen and (max-width: 1170px) {
    width: 50%;
    padding: 0px;
    .boxSliderImage {
      width: 420px;
      height: 370px;
      display: flex;
      padding: 0;
      justify-content: center;
      flex-direction: column;
    }
    .boxSliderParagraph {
      padding: 60px 170px 0px 97px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    img {
      width: 85%;
    }
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .colStepsDescription {
    display: none;
  }
}

/* new feature to logotipo */

.container-logos {
  height: 180px;
  display: flex;
  padding: 0px 11px;
  width: 100%;
  margin-top: -15px;
}

.container-logo {
  width: 50%;
  @media only screen and (max-width: 786px) {
    width: 100%;
  }
}

.container-logoLabel {
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
    margin-left: 5px;
  }
}

.label-logo {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0px;
  text-align: left;
}

.container-file {
  display: flex;
  height: 100px;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 786px) {
    justify-content: flex-start;
    align-items: center;
  }
}

.container-imgLogotipo {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-btnFile {
  margin-left: 25px;
}

.container-colors {
  @media only screen and (max-width: 786px) {
    margin-top: 120px;
  }
}

.onboardingForms {
  margin-left: 0px;
  padding: 20px;
  width: 100%;
  overflow: auto;
  height: 100vh;
  // max-width: 620px;
  max-width: 1000px;
}
