@media only screen {
  .team-data-container {
    min-width: 275px;
  }
}
@media only screen and (min-width: 64.063em) {
  .team-data-container {
    min-width: 425px;
  }
}

.team-data-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 325px;
  border-radius: 12px;
  padding: 1.8rem;
  filter: drop-shadow(0px 0px 16px rgba(17, 17, 17, 0.1));
  overflow-y: scroll;
  font-family: "raleway";
  background-color: #ffff;

  .team-data-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .team-data-header-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      .teams-data-logoContainer {
        width: 36px;
        height: 36px;
        background: rgba(47, 98, 170, 0.2);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        .team-data-logoImg {
          width: 18px;
          height: 12px;
        }
      }
      .teams-data-logoContainer:hover {
        background: rgba(10, 23, 41, 0.2);
      }

      .team-data-headerTitle {
        color: #000000;
        font-weight: 500;
        font-size: 1.5rem;
      }
    }
    .team-data-header-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.3rem;
    }
  }
  .team-data-body {
  }
}

.team-data-container::-webkit-scrollbar {
  width: 0.2em;
  background-color: #f5f5f5;
}

.team-data-container::-webkit-scrollbar-thumb {
  background-color: #000000;
}
.member-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  .member-avatar {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin-right: 1rem;
  }
  .member-name {
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 0.1rem;
    color: #000000;
  }
}

.teamdataLoading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
